import _ from 'lodash'
import * as React from 'react'
import { navigate } from 'gatsby'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import { withTrans } from '../i18n/withTrans'
import { useWindowDimensions } from '../helpers'
import {
  Button, P, Wrapper, H2,
} from '../components'
import imageNotFound from '../assets/images/TAM_404_banner.jpeg'
import './404.scss'

// markup
const NotFoundPage = ({ t }) => {
  const goTo = (e) => {
    e.preventDefault()
    // TODO: do something with form values
    navigate('/')
  }
  const size = useWindowDimensions()
  const width = _.get(size, 'width', 0)
  const breakpoints = useBreakpoint()

  return (
    <Wrapper
      paddingM="25px"
      paddingD=" 0 10px"
      marginD="56px auto"
      flexDirectionD="column"
      widthD="1240px"
    >
      <div className="not-fount-image-mobile">
        <Wrapper
          widthD="530px"
          heightD="530px"
          style={{
            borderRadius: '50%',
            overflow: 'hidden',
          }}
        >
          <img style={{ width: '100%' }}src={imageNotFound }/>
        </Wrapper>

      </div>
      <Wrapper
      >
        <Wrapper
          marginD="0"
          marginM="0 0 120px"
          alignItemsD="flex-start"
          justifyContentD="flex-start"

          flexDirectionD="column">
          <Wrapper
            marginD="48px 0"
            marginM="24px 0 40px"
            flexDirectionD="column"
            alignItemsD="flex-start"
            widthD="446px"
          >
            <H2
              fontSize="4xl"
              style={{ marginBottom: breakpoints.xs ? '15px' : '8px' }}>
              {t('notFound.title')}
            </H2>
            <P
              lineHeight="3xl"
              lineHeightD="3xl"
              style={{ textAlign: breakpoints.xs ? 'center' : 'left' }}
              fontSizeM="base">
              {t('notFound.subtitle')}
            </P>
            <P
              lineHeight="3xl"
              lineHeightD="3xl"
              style={{ textAlign: breakpoints.xs ? 'center' : 'left' }}
              fontSizeM="base">
              {t('notFound.thanks')}
            </P>
          </Wrapper>
          <Button
            widthD="200px"
            onClick={goTo}
            active>
            {t('notFound.button')}
          </Button>

        </Wrapper>
        {
          width >= 768 && (<Wrapper
            widthD="530px"
            heightD="530px"
            style={{
              borderRadius: '50%',
              overflow: 'hidden',
            }}
          >
            <img style={{ width: '100%' }}src={imageNotFound }/>
          </Wrapper>)

        }
      </Wrapper>
    </Wrapper>

  )
}

export default withTrans(NotFoundPage)
